import React from 'react'
import ModalWrapper from './ModalWrapper'
import ModalTitle from './ModalTitle'
import styled from 'styled-components';
import useKioskMode from '../hooks/useKioskMode';
const ContentWrapper = styled.div`
  max-height:800px;
  padding-left: 28px;
  padding-right: 28px;
  overflow:auto;
   @media(max-height:1000px){
    height:600px;
    ${props => props.kioskMode && `
      height:540px;
    `}
  }
`;

const Text = styled.p`
  color: black;
  font-family: 'Avenir Next Regular';
  font-size:16px;
  font-weight:normal;
  line-height:26px;
  overflow:auto;
  margin-top: 10px;
  >a{
    color:black;
  }
  >ul{
    li{
      list-style-type: none;
      >a{
        color:black;

      }
    }
  }
`;


function ProjektInfo(props) {
  const { show, closeModal } = props
  const [kioskMode] = useKioskMode();
  return (
    <ModalWrapper open={show} closeModal={closeModal}>
      <ModalTitle title={"Stoff Ohne Grenzen"}/>
      <ContentWrapper kioskMode={kioskMode}>
        <Text>
          Glückwunsch, du hast alle Geschichten entdeckt! Wusstest du, dass Kinder und Jugendliche bis 18 Jahre bei uns im tim freien Eintritt erhalten? Komm doch mal vorbei oder besuche unsere Webseite!

            <p>Staatliches Textil- und Industriemuseum Augsburg<br></br>
            Augsburger Kammgarnspinnerei<br></br>
            Provinostr. 46<br></br>
            86153 Augsburg<br></br>
            <a href='https://www.timbayern.de/' target="_blank">https://www.timbayern.de/</a>
            </p>

        </Text>
      </ContentWrapper>

    </ModalWrapper>
  )
}

export default ProjektInfo