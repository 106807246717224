import React from 'react'
import styled from 'styled-components';
import useKioskMode from './hooks/useKioskMode';

const Wrapper = styled.div`
  height:114px;
  width:180px;
  position:absolute;
  background-color:white;
  bottom:50px;
  ${props => props.kioskMode && `
    bottom:110px;
  `}
  right:50px;
  z-index:1;
  padding:10px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  font-style:italic;
  text-transform:uppercase;
`;

const Title = styled.h3`
  font-family:"Avenir Next Heavy";
  cursor: pointer;
  font-size:11px;
  line-height:0;
  font-weight:1000;
  letter-spacing: 1.2px;
`;

const List = styled.ul`
  letter-spacing: 1.2px;
  list-style:none;
  font-family:"Avenir Next Regular";
  font-size:11px;
  padding:0;
  margin:0;
  line-height:15px;
  font-style:italic;
  text-transform:uppercase;
  >li{
    cursor: pointer;
  }
  >a{
    text-decoration:none;
    color:black;
  }
`;


function InfoBlock(props) {
  const { setShowIntro, setShowProjectInfo, setDisableControls } = props
  const [kioskMode] = useKioskMode();
  const handleIntroClick = () => {
    setShowIntro(true)
  }

  const handleProjectInfoClick = () => {
    setShowProjectInfo(true)
    setDisableControls(true)
  }

  return (
    <Wrapper kioskMode={kioskMode}>
      <Title onClick={handleIntroClick}>Stoff ohne Grenzen</Title>
      <List>
        <li onClick={handleProjectInfoClick}>ProjektInfo</li>
        <a href="https://www.timbayern.de/datenschutzerklaerung/" target="_blank" rel="noreferrer"><li>Datenschutz</li></a> 
        <a href="https://www.timbayern.de/impressum/" target="_blank" rel="noreferrer"><li>Impressum</li></a>
      </List>
    </Wrapper>
  )
}

export default InfoBlock