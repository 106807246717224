import { useState, useEffect } from 'react';

const useKioskMode = () => {
  const [kioskMode, setKioskMode] = useState(false);

  useEffect(() => {
    // Check URL params
    const params = new URLSearchParams(window.location.search);
    const isKiosk = params.get('kioskMode');
    if (isKiosk === 'true') {
      setKioskMode(true);
      // Set cookie
      document.cookie = "kioskMode=true; path=/; max-age=31536000"; // 1 year expiry
    }
    
    // Check for existing cookie
    const cookies = document.cookie.split(';');
    const kioskCookie = cookies.find(c => c.trim().startsWith('kioskMode='));
    if (kioskCookie && kioskCookie.split('=')[1] === 'true') {
      setKioskMode(true);
    }
  }, []);

  return [kioskMode, setKioskMode];
};

export default useKioskMode;
